import {createRouter, createWebHistory} from "vue-router"
import adminRoutes from "@/router/adminRoutes"
import clientRoutes from "@/router/clientRoutes"
import authRoutes from "@/router/authRoutes"
import store from "@/services/store"

const routes = [
    {
        path: "/",
        name: "Client",
        component: () => import("@/layouts/ClientLayout"),
        children: clientRoutes
    },
    {
        path: "/auth",
        name: "Auth",
        component: () => import("@/layouts/AuthLayout"),
        children: authRoutes
    },
    {
        path: "/admin",
        name: "Admin",
        component: () => import("@/layouts/AdminLayout"),
        meta: {
            authRequired: true,
            authForbidden: false
        },
        children: adminRoutes
    },
    {
        path: "/:pathMatch(.*)",  // handling 404
        component: () => import("@/views/PageNotFound"),
        meta: {
            title: "Stránka nenalezena",
            authRequired: false,
            authForbidden: false
        }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

// protecting routes
router.beforeEach(async (to, from, next) => {
    // update store if needed
    if ((to.meta.authRequired || to.meta.authForbidden) && !store.state.isStoreUpdated) {
        await store.dispatch("updateStore");
    }

    // control routes
    const isAuthenticated = store.state.employeeLoggedIn;
    if (!isAuthenticated && to.meta.authRequired) {
        next({name: "AuthLogin"})
    } else if (isAuthenticated && to.meta.authForbidden) {
        next({name: "AdminDashboard"})
    } else {
        next()
    }
});

// update page title
router.afterEach((to) => {
    document.title = to.meta.title
})

export default router