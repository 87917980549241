import {createApp} from "vue"
import App from "./App.vue"

import router from "./router"
import api from "./services/api"
import store from "./services/store"
import FontAwesomeIcon from "./services/icons"

import VueToast from "vue-toast-notification"
import "vue-toast-notification/dist/theme-sugar.css"

const app = createApp(App)
    .use(router)
    .use(store)
    .component("font-awesome-icon", FontAwesomeIcon)
    .use(VueToast)

app.config.globalProperties.$api = api
app.mount("#app")