import {createStore} from "vuex";
import Api from "@/services/api";

const store = createStore({
    state: {
        employee: null,
        employeeLoggedIn: false,
        isStoreUpdated: false
    },
    mutations: {
        setEmployee(state, payload) {
            state.employee = payload;
        },
        setEmployeeIsLoggedIn(state, payload) {
            state.employeeLoggedIn = payload;
        },
        setIsStoreUpdated(state, payload) {
            state.isStoreUpdated = payload;
        }
    },
    actions: {
        async login(context, {email, password}) {
            const res = await Api.post("/employees/login", {email, password})
                .catch((err) => {
                    throw err;
                });
            if (res && res.data) {
                context.commit("setEmployee", res.data.employee);
                context.commit("setEmployeeIsLoggedIn", true);
            } else {
                context.commit("setEmployee", null);
                context.commit("setEmployeeIsLoggedIn", false);
                throw new Error("unknown error");
            }
        },
        async updateStore(context) {
            try {
                const res = await Api.get("/employees");
                context.commit("setEmployee", res.data.employee);
                context.commit("setEmployeeIsLoggedIn", true);
            } catch (e) {
                context.commit("setEmployee", null);
                context.commit("setEmployeeIsLoggedIn", false);
            } finally {
                context.commit("setIsStoreUpdated", true);
            }
        },
        async logout(context) {
            const res = await Api.get("/employees/logout")
                .catch((err) => {
                    throw err;
                });
            if (res) {
                context.commit("setEmployee", null);
                context.commit("setEmployeeIsLoggedIn", false);
            }
        }
    }
})

export default store;