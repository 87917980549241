<template>
  <router-view/>
</template>

<script>

export default {
  name: "App"
}
</script>

<style>
/*@font-face {*/
/*  font-family: Poppins;*/
/*  font-style: normal;*/
/*  font-weight: 400;*/
/*  src: local("Poppins"),*/
/*  local("Poppins-Regular"),*/
/*  url("./assets/fonts/poppins.woff2") format("woff2"), url('./assets/fonts/poppins.ttf') format('truetype');*/
/*  font-display: swap;*/
/*}*/

/*@font-face {*/
/*  font-family: "Dancing Script";*/
/*  font-style: normal;*/
/*  font-weight: 400;*/
/*  src: local("Dancing Script"),*/
/*  local("DancingScript-Regular"),*/
/*    !*url("./assets/fonts/dancing-script.woff2") format("woff2"),*! url('./assets/fonts/dancing-script.ttf') format('truetype');*/
/*  font-display: swap;*/
/*}*/

:root {
  --primary: #ECA459;
  --primary-hover: #c5792b;
  --white: #fdfdfd;
  --black: #101010;
  --light-gray: #1d1d1d;
  --text-gray: #676666;
  --success: #049604;
  --error: #ff4444
}

#app {
  font-family: Poppins, sans-serif;
  color: var(--black);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
