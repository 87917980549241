export default [
    {
        path: "",
        name: "AuthLogin",
        component: () => import("@/views/auth/LoginView"),
        meta: {
            title: "Přihlášení",
            authRequired: false,
            authForbidden: true
        }
    }
]