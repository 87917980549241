export default [
    {
        path: "",
        name: "AdminDashboard",
        component: () => import("@/views/admin/DashboardView"),
        meta: {
            title: "Hlavní deska",
        },
    },
    {
        path: "druhy-jidel",
        name: "AdminFoodGroupList",
        component: () => import("@/views/admin/FoodGroupListView"),
        meta: {
            title: "Druhy jídel",
        },
    },
    {
        path: "druhy-jidel/vytvorit",
        name: "AdminFoodGroupCreate",
        component: () => import("@/views/admin/FoodGroupFormView"),
        meta: {
            title: "Vytvořit druh jídla",
        },
    },
    {
        path: "druhy-jidel/:foodGroupId/upravit",
        name: "AdminFoodGroupEdit",
        component: () => import("@/views/admin/FoodGroupFormView"),
        meta: {
            title: "Upravit druh jídla",
        },
    },
    {
        path: "jidelni-listek",
        name: "AdminFoodList",
        component: () => import("@/views/admin/FoodListView"),
        meta: {
            title: "Jídelní lístek",
        },
    },
    {
        path: "jidelni-listek/vytvorit",
        name: "AdminFoodCreate",
        component: () => import("@/views/admin/FoodFormView"),
        meta: {
            title: "Vytvořit jídlo",
        },
    },
    {
        path: "jidelni-listek/:foodId/upravit",
        name: "AdminFoodEdit",
        component: () => import("@/views/admin/FoodFormView"),
        meta: {
            title: "Upravit jídlo",
        },
    },
    {
        path: "newsletter",
        name: "AdminNewsletterList",
        component: () => import("@/views/admin/NewsletterList"),
        meta: {
            title: "Newsletter"
        }
    },
    {
        path: "novinky",
        name: "AdminAnnouncementList",
        component: () => import("@/views/admin/AnnouncementListView"),
        meta: {
            title: "Novinky"
        }
    },
    {
        path: "novinky/vytvorit",
        name: "AdminAnnouncementCreate",
        component: () => import("@/views/admin/AnnouncementForm"),
        meta: {
            title: "Vytvořit novinku"
        }
    },
    {
        path: "novinky/:announcementId/upravit",
        name: "AdminAnnouncementEdit",
        component: () => import("@/views/admin/AnnouncementForm"),
        meta: {
            title: "Upravit novinku"
        }
    },
    {
        path: "rezervace",
        name: "AdminReservationList",
        component: () => import("@/views/admin/ReservationListView"),
        meta: {
            title: "Rezervace"
        }
    },
    {
        path: "rezervace/:reservationId",
        name: "AdminReservationDetail",
        component: () => import("@/views/admin/ReservationDetailView"),
        meta: {
            title: "Rezervace"
        }
    },
    {
        path: "menu",
        name: "AdminMenuList",
        component: () => import("@/views/admin/MenuListView"),
        meta: {
            title: "Meníčka"
        }
    },
    {
        path: "menu/vytvorit",
        name: "AdminMenuCreate",
        component: () => import("@/views/admin/MenuFormView"),
        meta: {
            title: "Vytvořit meníčko"
        }
    },
    {
        path: "menu/:menuId/upravit",
        name: "AdminMenuEdit",
        component: () => import("@/views/admin/MenuFormView"),
        meta: {
            title: "Upravit meníčko"
        }
    },
]