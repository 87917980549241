export default [
    {
        path: "",
        name: "Home",
        component: () => import("@/views/client/HomeView"),
        meta: {
            title: "Rozsocháč - restaurace a penzion",
            authRequired: false,
            authForbidden: false
        }
    },
    {
        path: "/novinky",
        name: "Announcements",
        component: () => import("@/views/client/AnnouncementListView"),
        meta: {
            title: "Novinky",
            authRequired: false,
            authForbidden: false
        }
    },
    {
        path: "/novinky/:announcementId",
        name: "Announcement",
        component: () => import("@/views/client/AnnouncementView"),
        meta: {
            title: "Novinka",
            authRequired: false,
            authForbidden: false
        }
    },
    {
        path: "/menu",
        name: "Menu",
        component: () => import("@/views/client/MenuView"),
        meta: {
            title: "Týdenní menu",
            authRequired: false,
            authForbidden: false
        }
    },
    {
        path: "/menu/rezervace",
        name: "MenuReservation",
        component: () => import("@/views/client/MenuReservationView"),
        meta: {
            title: "Rezervace meníček",
            authRequired: false,
            authForbidden: false
        }
    },
    {
        path: "/jidelni-listek",
        name: "Food",
        component: () => import("@/views/client/FoodView"),
        meta: {
            title: "Jídelní lístek",
            authRequired: false,
            authForbidden: false
        }
    },
    {
        path: "/galerie",
        name: "Gallery",
        component: () => import("@/views/client/GalleryView"),
        meta: {
            title: "Galerie",
            authRequired: false,
            authForbidden: false
        }
    },
    {
        path: "/rezervace",
        name: "Reservation",
        component: () => import("@/views/client/ReservationView"),
        meta: {
            title: "Rezervace",
            authRequired: false,
            authForbidden: false
        }
    }
]