import {library} from "@fortawesome/fontawesome-svg-core"
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome"
import {faFacebookSquare, faInstagramSquare} from "@fortawesome/free-brands-svg-icons"
import {
    faCircleNotch, faPhone, faEnvelope, faMapMarkerAlt, faWifi, faBirthdayCake, faLeaf, faFile, faFilePdf, faDownload,
    faHouse, faUtensils, faBeer, faBed, faCircleLeft, faCircleRight, faTable, faList, faBookmark, faInbox, faPowerOff,
    faPenToSquare, faTrash, faCircleQuestion, faSquareCheck, faSquareXmark, faTicket, faArrowRight, faMagnifyingGlass,
    faDrumstickBite
} from "@fortawesome/free-solid-svg-icons"

library.add({
    faCircleNotch, faPhone, faEnvelope, faMapMarkerAlt, faWifi, faBirthdayCake, faLeaf, faFile, faFilePdf, faDownload,
    faHouse, faUtensils, faBeer, faBed, faCircleLeft, faCircleRight, faTable, faList, faBookmark, faInbox, faPowerOff,
    faPenToSquare, faTrash, faCircleQuestion, faSquareCheck, faSquareXmark, faTicket, faArrowRight, faMagnifyingGlass,
    faDrumstickBite,

    faFacebookSquare, faInstagramSquare
})

export default FontAwesomeIcon